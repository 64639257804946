@import url(https://fonts.googleapis.com/css?family=Merriweather:300|Raleway:800&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  scroll-behavior: smooth;
  box-sizing: border-box;
  height: 100%;
}

h1,
h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}

body {
  font-family: "Merriweather", serif;
  font-weight: 200;
}

.App-header {
  border-top: 2px solid #c3621c;
  background-color: whitesmoke;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: black;
  padding: 5%;
}

form .btn-custom {
  background-color: #ffa000;
  padding: 10px;
  border: none;
  width: 60%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: #fff;
}

form .btn-custom:hover,
form .btn-custom:active {
  opacity: 1;
  background-color: #c3621c;
  color: #fff;
  outline: none;
}

form .btn-custom:focus {
  outline: 0;
  border: none;
}

.content-box {
  border: solid 1px lightgray;
  border-radius: 0.25rem;
  padding: 2rem;
  box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.1);
  width: 40vw;
  border-bottom: 2px solid #c3621c;
}

.player-card {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column;
          flex-flow: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.player-card img {
  /* -webkit-box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.2);
  -moz-box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.2);
  box-shadow: 3px 3px 5px 0px rgba(50, 50, 50, 0.2); */
  border-radius: 0.25rem;
  background-color: rgba(50, 50, 50, 0.1);
  padding-bottom: 10%;
  border-bottom: 2px solid #c3621c;
}

.player-card h1,
.player-card b {
  color: #c3621c;
}

.text-left {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

label {
  font-weight: lighter;
  margin-right: 0.5rem;
  position: relative;
}

.form-control {
  margin: 0 10% 0 10%;
}

.error-field {
  color: red;
  font-size: small;
  width: 100%;
}

.loader {
  border: 8px solid lightgray;
  border-top: 8px solid #ffa000;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.disclaimer {
  font-size: 16px;
  color: #999;
}

.disclaimer a,
.disclaimer a:hover {
  color: #c3621c;
}

@media only screen and (max-width: 900px) {
  .content-box {
    width: 80vw;
  }
}

